
html {
  scroll-behavior: smooth;
}
.center-al {
  text-align: center;
}

.large-font {
  font-size: 24px !important;
  line-height: 20px !important;
  vertical-align: top;
}

.spacer {
  height: 80px;
}
.logo{
  width: 80%;
  margin: 20px;
}