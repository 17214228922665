@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);

html {
  scroll-behavior: smooth;
}
.center-al {
  text-align: center;
}

.large-font {
  font-size: 24px !important;
  line-height: 20px !important;
  vertical-align: top;
}

.spacer {
  height: 80px;
}
.logo{
  width: 80%;
  margin: 20px;
}
.titles{
  text-align: right;
  margin: 2px !important;
  text-transform: uppercase;
  font-weight: bolder !important;
  color: #0A368B !important;
}

.images{
  border-radius: 20px;
  width: 40vw;
  
  margin: 5vw;
}

img.images{
  height: 40vw;
}

.inlineM{
  display: inline-flex;
}
.imagebottom{
  width: 300px;
  margin: 80px 10%;
  height: 100%;
}


.checkout-input{
  width: 300px !important;
  display: block !important;
  margin: 10px auto !important;
  border-color: #303030 !important;
}


@media screen and (max-width: 900px) {
  .titles{
    font-size: 1.5em !important;
  }

}

@media screen and (max-width: 600px) {
  .titles{
    font-size: 1.2em !important;
  }
  .inlineM{
    display: block;
  }
  .images{
    border-radius: 20px;
    width: 80vw;
    
    margin: 5vw;
  }
  img.images{
    height: 80vw;
  }
  .imagebottom{
    width: 200px;
    margin: 50px auto;
    height: 100%;

  }

}


/* .background{ 
  background-image: url('./img/back.jpg');

} */

.logo {
  height: 60px;
  width: auto;
  margin: 1vh 20px;
}

.border {

  height: 34vh;
  width: 33.333%;
  text-align: center;
  cursor: pointer;
  transition: 0.2s;
}
.border:hover{
  transition: 0.3s;
  background-size: 2;
  background-position: center;
}

.center-text{
  line-height: 34vh;
  color: white !important;
  font-size: 18px !important;

  transition: 0.2s;
  font-weight: bold;
  /* text-shadow: 0px 0px 10px rgb(255, 255, 255); */
  /* -webkit-text-stroke-width: 0.8px; */
  -webkit-text-stroke-color: white;
}

.center-text:hover{
  font-size: 24px;
  transition: 0.2s;
}
.header{
  height: 80px;
}
.third{
  width: 50%;
  height: 10vh;
}
.center-link{
  font-size: 1.2em;
  text-align: right;
  padding-right: 20px;
}
a{
  color: #303030;
}

/* BACKGROUNDS */


.photo {
  width: 90%;
  margin: 5% 5% 0px 5%;
  height: auto;
}

.h1{
  font-family: 'Pacifico', cursive;
  color: rgb(131,27,41) !important;
  text-align: center;
}
h2{
  text-align: center;
}
.description{
  color: #707070 !important;
  text-align: center;
  margin: auto 10%;
}
.add-button{
  margin: 10px 20%;
  width: 60%;
}

.futerLink {display: inline-flex}
		.futerImg {height: 30px;
					width: 30px;
					margin: 4px 5px 0px 5px}
		.futerImg:hover {
					width: 34px;
					height:34px;
			margin: 0px 3px 0px 3px;
		}

.footer{
  padding: 20px 0px 20px;
}
.center-button{
  margin: 20px 10px;
}
@media screen and (max-width: 600px) {
  h1{
    font-size: 1.6em;
  }
  h2{
    font-size: 1.2em;
  }
  .header{
    height: 60px;
  }
  .logo{
    height: 40px;
    margin: 10px 10px;
  }
  .center-button{
  margin: 15px 0px;
  }

  .border {
    border: solid 1px black;
    height: 20vh;
    width: 100vw;
    text-align: center;
    cursor: pointer;

    background-size: 100%;
    transition: 0.2s;
    background-position: center;
  }
  .center-text{
    line-height: 20vh;
    color: white;
    text-shadow: 0px 0px 10px 5px rgba(0,0,0,0.75);
  }
  .center-link{
    font-size: 1em;
  }
}
